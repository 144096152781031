<template>
  <th-wrapper
    v-loading="loading"
    :title="$t('common.titles.general_info.title')"
    class="m-8"
  >
    <el-form ref="form" :model="current" :rules="rules">
      <el-row :gutter="10">
        <el-col :md="12">
          <!-- Name -->
          <el-col>
            <el-form-item prop="name" :label="$t('common.forms.labels.name')">
              <el-input
                id="name"
                v-model="current.name"
                :placeholder="
                  $t('pages.reservations.service_category.name.placeholder')
                "
              />
            </el-form-item>
          </el-col>
          <!-- Description -->
          <el-col>
            <el-form-item
              prop="description"
              :label="$t('common.forms.labels.description')"
            >
              <el-input
                id="description"
                v-model="current.description"
                type="textarea"
                :rows="3"
                :placeholder="
                  $t(
                    'pages.reservations.service_category.description.placeholder'
                  )
                "
              />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import pick from 'just-pick'
import serviceCategoryModel from '../model/service-category-model'

function generateDefault() {
  return {
    name: null,
    description: null
  }
}

export default {
  name: 'ServiceCategoryForm',
  setup() {
    return serviceCategoryModel.setup(generateDefault())
  },
  data() {
    return {
      resources: {},
      loading: false,
      uniqueNameError: false,
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.reservations.service_category.name.required'
            )
          },
          {
            validator: this.validateUniqueName,
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ],
        description: [
          {
            max: 4096,
            message: this.$t('common.forms.rules.max_length', { length: 4096 })
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    isNew() {
      return !this.$route.params.id
    }
  },
  watch: {
    'current.name': function () {
      if (this.uniqueNameError) this.uniqueNameError = false
    }
  },
  async created() {
    this.id = this.$route.params.id
  },
  async mounted() {
    if (!this.isNew) await this.fetchResources(this.$route.params.id)
  },
  methods: {
    async fetchResources(id) {
      try {
        const inst = th.productGroups()

        this.loading = true
        const { data = {} } = await inst.get(id)

        if (data.id) {
          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t(
              'common.resource.reservations.service_category.singular'
            )
          })
        })
      } finally {
        this.loading = false
      }
    },
    handleItem(item) {
      this.payload = { ...item }
      const cleanedPayload = pick(item, Object.keys(this.current))

      this.current = {
        ...this.current,
        ...cleanedPayload
      }
    },
    async submitForm() {
      const resource = this.$t(
        'common.resource.reservations.service_category.singular'
      )

      const errorMessage = this.isNew
        ? this.$t('common.error.action.create.single', { resource })
        : this.$t('common.error.action.update.single', { resource })

      const successMessage = this.isNew
        ? this.$t('common.success.action.create.single', { resource })
        : this.$t('common.success.action.update.single', { resource })

      const ampliEvent = this.isNew
        ? 'serviceCategoryCreated'
        : 'serviceCategoryUpdated'

      const formValid = await this.$refs.form.validate()
      if (!formValid) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'common.forms.rules.field_warnings.invalid_inputs.required'
          )
        })
      }

      const { error } = await this.save()

      if (!error) {
        this.$ampli.eventWithBaseProps(ampliEvent)
      }

      this.checkUniqueNameError(error)

      //handle errors
      if (error) {
        if (this.uniqueNameError) {
          const errorMessage = this.$t(
            'pages.reservations.service_category.name.unique'
          )
          this.$logException(error, {
            message: errorMessage
          })
        } else {
          this.$logException(error, {
            message: errorMessage
          })
        }
        //handle success
      } else if (this.id) {
        this.$message({
          type: 'success',
          message: successMessage
        })

        if (this.navigationAfterCreation === 'edit') {
          this.$router.push({
            name: 'service-category-edit',
            params: { id: this.id }
          })
        } else {
          this.$router.push({ name: 'service-category' })
        }
      }
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(this.current.name || this.id)
      if (confirm) this.deleteServiceCategory(this.id)
    },
    async deleteServiceCategory(id) {
      const resource = this.$t(
        'common.resource.reservations.service_category.singular'
      )
      const successMessage = this.$t('common.success.action.delete.single', {
        resource
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource
      })

      try {
        const inst = th.productGroups()
        await inst.delete(id)
        this.$ampli.eventWithBaseProps('serviceCategoryDeleted')
        this.$message({
          type: 'success',
          message: successMessage
        })

        //handle success
        this.$router.push({ name: 'service-category' })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    checkUniqueNameError(err) {
      const errorMsg = safeGet(err, 'properties.error.response.data.msg') || ''
      if (errorMsg.includes('already exists')) {
        this.uniqueNameError = true
        this.$refs.form.validate()
      }
    },
    validateUniqueName(rule, value, callback) {
      if (this.uniqueNameError) {
        callback(
          new Error(this.$t('pages.reservations.service_category.name.unique'))
        )
      } else {
        callback()
      }
    }
  }
}
</script>
