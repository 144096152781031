<template>
  <th-page-wrapper
    :actions="actions"
    @save="handleSubmit"
    @delete="handleDelete"
  >
    <service-category-form ref="form" />
  </th-page-wrapper>
</template>

<script>
import ServiceCategoryForm from './components/form.vue'

export default {
  name: 'ServiceCategoryEdit',
  components: {
    ServiceCategoryForm
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'reservations:service_categories',
        returnedPath: { name: 'service-category-edit' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>
